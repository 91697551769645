import axios from "axios"

export async function getDocumentList(userID) {
    const response = await axios.get("/getDocumentList", {
        params: {
            userID
        }
    });
    const status = response.headers["docmgmt-status"]
    return [response.data, status]
}

export async function getDocumentNFT(userID, docID, docHash) {
    const response = await axios.get("/getDocumentNFT", {
        params: {
            userID,
            docID,
            docHash
        }
    });
    const status = response.headers["docmgmt-status"]
    return [response.data, status]
}

export async function getDocument(userID, docID, nftID) {
    const response = await axios.get("/getDocument", {
        params: {
            userID,
            docID,
            nftID
        }
    });
    const status = response.headers["docmgmt-status"]
    return [response.data, status]
}
