import { createApp } from 'vue'
import App from './App.vue'
import Keycloak from 'keycloak-js';
import store from "./store"
import router from "./router"
import "./assets/style/common.css"
import dayjs from "dayjs"
import isBetween from "dayjs/plugin/isBetween"
dayjs.extend(isBetween)

const url = process.env.VUE_APP_KEYCLOAK_URL
const realm = process.env.VUE_APP_REALM
const clientId = process.env.VUE_APP_CLIENT_ID
const keycloak = new Keycloak({
  url,
  realm,
  clientId
});

// see https://qiita.com/maaaashi/items/b99e123129735deb5576
// see https://stackoverflow.com/questions/73505559/client-secretapp-realm-demo-not-provided-in-request-error-keycloak-react-typescript
try {
  const authenticated = await keycloak.init({
    onLoad: 'login-required',
    checkLoginIframe: false
  });
  store.commit("setPayload", keycloak.tokenParsed)
  store.commit("setKeycloak", keycloak)
  console.log(`User is ${authenticated ? 'authenticated' : 'not authenticated'}`);
  // start vue application
  createApp(App).use(store).use(router).mount('#app')
} catch (error) {
  console.error('Failed to initialize adapter:', error);
}

