<template>
  <head>
    <!-- head内に他の設定があればその下にFontAwesomeのCDNを追加 -->
    <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css" rel="stylesheet">
  </head>
  <div>
    <div class="user-section-wrapper">
      <div class="user-section">
        <button v-if="isViewer" class="back-button" @click="goBack">
          <i class="fas fa-chevron-left"></i> <!-- FontAwesomeのアイコン -->
        </button>
        <!-- ハンバーガーメニュー -->
        <div class="menu-toggle" @click="toggleMenu">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <!-- メニュー項目 -->
        <div class="menu" :class="{ 'open': isMenuOpen }">
          <div class="menu-section">
            <div class="menu-items" v-show="isMenuOpen">
              <div class="user-id">
                ユーザID:{{ userId }}
              </div>
              <button class="logout-button button-right" @click="logout">ログアウト</button>
            </div>
          </div>
        </div>
      </div>
      <div class="switch-wrapper">
        <label for="switch" class="switch-label">
          <input v-model="penDetected" type="checkbox" id="switch" />
          {{ `筆記用具検出：${penDetected ? "オン" : "オフ"}`}}
        </label>
        <label for="switch-camera" class="switch-label">
          <input v-model="displayCamera" type="checkbox" id="switch-camera" />
          {{ `カメラ：${displayCamera ? "表示" : "非表示"}`}}
        </label>
      </div>
      <div id="unidnp" v-if="displayCamera">
        <iframe id="unidnp_flame" src="https://platfield.com/face.html" frameborder="no" scrolling="no" allow="camera"></iframe>
      </div>
    </div>
    <div id="unidob" v-if="displayCamera">
      <iframe class="unidob_flame" src="https://platfield.com/obj.html" frameborder="no" scrolling="no" allow="camera"></iframe>
    </div>
    <FaceRecognitionModal @close="close" :show-modal="!faceRecognition.enabled" :message="faceRecognition.message" />
    <div class="container" :style="containerPadding">
      <router-view />
    </div>
  </div>
</template>
<script setup>
import { useStore }  from "vuex";
import { useRoute } from "vue-router"
import { computed, onMounted, ref, provide }  from "vue";
import FaceRecognitionModal from "@/components/FaceRecognitionModal.vue";
import dayjs from "dayjs";

const store = useStore()
const route = useRoute()
const userId = computed(() => {
  return store.getters.userId
})

const faceRecognition = ref({
  enabled: true,
  message: ""
})

const containerPadding = computed(() => {
  return !displayCamera.value ? {
    "padding-top": "100px"
  } : {}
})

const penDetectedSwitch = ref(false)
const penDetected = computed({
  set(checked) {
    if (!checked) {
      faceRecognition.value = {
        enabled: true,
        message: ""
      }
    }
    penDetectedSwitch.value = checked
  },
  get() {
    return penDetectedSwitch.value
  }
})

const displayCameraSwitch = ref(true)
const displayCamera = computed({
  set(checked) {
    if (!checked) {
      faceRecognition.value = {
        enabled: true,
        message: ""
      }
      penDetected.value = false
    }
    displayCameraSwitch.value = checked
  },
  get() {
    return displayCameraSwitch.value
  }
})


provide("faceRecognition", faceRecognition)

function handleMessage(e) {
  console.log("handleMessage", e)
  if(e.origin !== "https://platfield.com") {
    return
  }

  const [eventName, data] = e.data;
  console.log("eventName", eventName)
  
  if (!faceRecognition.value.enabled || !displayCameraSwitch.value) {
    return;
  }

  switch (eventName) {
    case 'faceDetected':
      if (data) {
        faceRecognition.value = {
          enabled: false,
          message: "覗き見が検出されました",
        }
        break;
      }
      break;
    case 'noItemDetected':
    case 'allowAccess':
      faceRecognition.value = {
        enabled: true,
        message: ""
      };
      break;
    case 'phoneDetected':
      faceRecognition.value = {
        enabled: false,
        message: "スマホが検出されました"
      };
      break;
    case 'cameraDetected':
      faceRecognition.value = {
        enabled: false,
        message: "カメラが検出されました"
      };
      break;
    case 'penDetected':
      if(penDetectedSwitch.value) {
        faceRecognition.value = {
          enabled: false,
          message: "筆記用具が検出されました"
        };
      } else {
        faceRecognition.value = {
          enabled: true,
          message: ""
        };
      }
      break;
    // default: {
    //   faceRecognition.value = {
    //     enabled: false,
    //     message: "顔が検出できません"
    //   };
    // }
  }
}

onMounted(() => {
  document.addEventListener("contextmenu", (event) => {
    event.preventDefault()
  })
  console.log("a", store.state.keycloak.tokenParsed.exp)
  setInterval(() => {
    // const expire = store.state.keycloak.refreshTokenParsed.exp

    const expire = store.state.keycloak.tokenParsed.exp
    console.log()
    if (dayjs().isAfter(dayjs.unix(expire))) {
      store.state.keycloak.logout()
    }
  },60000)
  document.oncontextmenu = function () { return false; }
  window.addEventListener("message", handleMessage)
})

function goBack (){
  window.history.back();
}

const isViewer = computed(() => {
  return route.path.startsWith("/viewer")
})

const logout = () => {
  store.state.keycloak.logout()
}

const close = () => {
  faceRecognition.value = {
    enabled: true,
    message: "",
  }
}

// メニューの開閉状態を管理する変数
const isMenuOpen = ref(false);

// メニューのトグル処理
function toggleMenu() {
  isMenuOpen.value = !isMenuOpen.value;
}
</script>

<style scoped>

.user-id {
  background-color: #444;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  border-bottom-style: inset;
  border-bottom-color: black;
  font-size: 16px;
}

.user-section-wrapper {
  position: fixed;
  width: 100%;
}

.user-section {
  display: flex;
  padding: 10px 10px;
}

/* 戻るボタンのスタイル */
.back-button {
  background-color: #333; /* ボタンの背景色 */
  color: white; /* テキストの色 */
  border: none; /* ボーダーをなしに */
  text-align: center; /* テキストを中央に配置 */
  text-decoration: none; /* テキストの下線をなしに */
  display: inline-block; /* インラインブロック要素にする */
  font-size: 16px; /* フォントサイズ */
  cursor: pointer; /* カーソルをポインターに変更 */
  border-radius: 5px; /* ボーダーの角を丸くする */
  padding: 0 10px; /* アイコンの位置調整 */
}

/* ログアウトボタンのスタイル */
.logout-button {
  color: white; /* テキストの色 */
  border: none; /* ボーダーをなしに */
  text-align: center; /* テキストを中央に配置 */
  text-decoration: none; /* テキストの下線をなしに */
  display: inline-block; /* インラインブロック要素にする */
  cursor: pointer; /* カーソルをポインターに変更 */
  border-radius: 5px; /* ボーダーの角を丸くする */
  background-color: #444;
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
}

.button-right {
  margin-left: auto;
}

/* ホバー時のスタイル */
.back-button:hover {
  background-color: #222; /* ホバー時の背景色 */
}

/* ハンバーガーメニューのスタイル */
.menu {
  display: none;
  position: absolute;
  top: 224px;
  right: 0;
  flex-direction: column;
  background-color: #f9f9f9;
  width: 100%;
}

@media screen and (min-width: 817px) {
  .menu {
    top: 394px;
  }
}

.menu.open {
  display: flex;
}

.menu-items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-items .back-button {
  margin-top: 10px;
}

.menu-section {
  background-color: #333;
  color: #fff;
}

.menu-toggle {
  display: flex;
  align-items: center;
  margin-left: auto;
  flex-direction: column;
  cursor: pointer;
}

.menu-toggle div {
  width: 25px;
  height: 3px;
  background-color: #f9f9f9;
  margin: 4px 0;
  transition: transform 0.3s, opacity 0.3s;
}

.menu.open .menu-toggle div:first-child {
  transform: translateY(8px) rotate(45deg);
}

.menu.open .menu-toggle div:nth-child(2) {
  opacity: 0;
}

.menu.open .menu-toggle div:last-child {
  transform: translateY(-8px) rotate(-45deg);
}

@media screen and (max-width: 816px) {
  .back-button {
    padding: 8px 12px; /* パディング */
  }
}

@media screen and (min-width: 817px) {
  #unidnp iframe {
    height: 300px;
    width: 320px;
  }
}

#unidnp {
  display: flex;
  justify-content: center;
  padding: 10px;
}

#unidnp iframe {
  max-width: 100%;
}

#unidnp_frame p {
  display: none;
}

#unidob, #unidob iframe {
  width: 0;
  height: 0;
}

.switch-wrapper {
  text-align: center;
}
</style>

<style>
@media print {
  body {
    display: none;
  }
}

#unidnp iframe #message {
  display: none;
}
</style>
