<template>
  <div class="pdf-container" ref="pdfContainer">
  </div>
  <ErrorModal @close="close" :show-modal="errorInfo.showModal && faceRecognition.enabled" :error-code="errorInfo.errorCode" />
</template>

<script setup>
import  * as pdfjs from "pdfjs-dist";
import {inject, onMounted, ref} from "vue";
import { useRoute } from "vue-router";
import { useDocument } from "@/utils/useDocument";
import ErrorModal from "@/components/ErrorModel.vue";
import { useErrorModal } from "@/utils/useErrorModal";

const faceRecognition = inject("faceRecognition")
const pdfContainer = ref(null);
const route = useRoute()
const { getDocumentNFT, getDocument, errorInfo } = useDocument()
const { close } = useErrorModal(errorInfo)

const renderPDF = (document) => {
  const pdfData = atob(document);

  pdfjs.GlobalWorkerOptions.workerPort =
      new Worker(new URL("pdfjs-dist/build/pdf.worker.js", import.meta.url));

  let currentPage = 1;
  let totalPage = 0;
  let pdfDoc = null;
  const render = (page) => {
    const canvas = window.document.createElement("canvas")
    const scale = 2;
    const viewport = page.getViewport({ scale });
    const context = canvas.getContext('2d');
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    canvas.style.margin = "15px auto"
    canvas.style.display = "block"

    const renderContext = {
      canvasContext: context,
      viewport: viewport
    };
    // 画面遷移後すぐ戻るを押下するとpdfContainerが空のため
    if(!pdfContainer.value) {
      return;
    }
    pdfContainer.value.appendChild(canvas)
    currentPage++;
    page.render(renderContext).promise.then(() => {
      if(currentPage <= totalPage) {
        pdfDoc.getPage(currentPage).then(render)
      }
    });
  };

  pdfjs.getDocument({
    data: pdfData,
    cMapUrl: "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.943/cmaps/",
    cMapPacked: true,
    pdfBug: true,
    verbosity: 5,
  }).promise.then(pdf => {
    pdfDoc = pdf
    totalPage = pdf.numPages
    pdfDoc.getPage(currentPage).then(render);
  });
}

onMounted(async () => {
  const { docID, docHash } = route.query
  const documentNFT = await getDocumentNFT(docID, docHash);
  if (errorInfo.value.errorCode !== "" && errorInfo.value.errorCode !== "200") {
    return
  }
  const document = await getDocument(docID, documentNFT.nftID)
  if (errorInfo.value.errorCode !== "" && errorInfo.value.errorCode !== "200") {
    return
  }

  renderPDF(document)
});
</script>

<style>
.pdf-container {
  padding: 15px 0;
  text-align: center;
}

@media screen and (max-width: 816px) {
  .pdf-container canvas {
    width: 816px;
    max-width: 100%;
  }
}

@media screen and (min-width: 817px) {
  .pdf-container canvas {
    width: auto;
    min-width: 817px;
  }
}

</style>
