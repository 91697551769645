<template>
  <div class="modal" v-if="props.showModal">
    <div class="modal-wrapper">
      <div class="modal-content">
        {{ errorMessage }}
        <div>
          <button class="close-button" @click="emit('close')">閉じる</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps, defineEmits, computed} from "vue";

const emit = defineEmits(["close"])
const props = defineProps({
  showModal: {
    required: true,
    default: false
  },
  errorCode: {
    required: false,
    default: ""
  }
});

const errorMessage = computed(() => {
  if(props.errorCode === "701") {
    return "文書が改ざんされています"
  }
  return `エラーが発生しました。管理者にお問い合わせください。（エラーコード：${ props.errorCode }）`
})
</script>

<style scoped>
/* モーダルのスタイル */
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.modal-wrapper {
  padding: 8px;
}
.modal-content div {
  text-align: center;
}
.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}

.close-button {
  margin-top: 15px;
  background-color: #4CAF50; /* ボタンの背景色 */
  color: white; /* テキストの色 */
  border: none; /* ボーダーをなしに */
  padding: 10px 20px; /* パディング */
  text-align: center; /* テキストを中央に配置 */
  text-decoration: none; /* テキストの下線をなしに */
  display: inline-block; /* インラインブロック要素にする */
  font-size: 16px; /* フォントサイズ */
  cursor: pointer; /* カーソルをポインターに変更 */
  border-radius: 5px; /* ボーダーの角を丸くする */
}
</style>
