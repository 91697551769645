export function useErrorModal(errorInfo) {
    const close = () => {
        errorInfo.value = {
            showModal: false,
            errorCode: "",
        }
    }
    return {
        close
    }
}