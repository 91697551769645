import * as api from "../utils/api";
import { ref }  from "vue";
import dayjs from "dayjs"
import { useStore } from "vuex";


export const useDocument = () => {
    const documents = ref([])
    const store = useStore()
    const errorInfo = ref({
        showModal: false,
        errorCode: "",
    })
    const userID = store.state.userId

    const isWithinPeriod = (document) => {
        const startDate = dayjs(document.expDateStart, "YYYY/MM/DD")
        const endDate = dayjs(document.expDateEnd, "YYYY/MM/DD")
        return dayjs().isBetween(startDate, endDate.endOf("day"), null, "[]")
    }

    const getDocumentList = async () => {
        const [response, status] = await api.getDocumentList(userID)
        if (status !== "200") {
            errorInfo.value = {
                showModal: true,
                errorCode: status,
            }
        }
        return response
    }

    const getDocumentNFT = async (docID, docHash) => {
        const [response, status] = await api.getDocumentNFT(userID, docID, docHash)
        if (status !== "200") {
            errorInfo.value = {
                showModal: true,
                errorCode: status,
            }
        }
        return response
    }

    const getDocument = async (docID, nfaID) => {
        const [response, status] = await api.getDocument(userID, docID, nfaID)
        if (status !== "200") {
            errorInfo.value = {
                showModal: true,
                errorCode: status,
            }
        }
        return response
    }

    return {
        errorInfo,
        documents,
        getDocumentNFT,
        getDocument,
        getDocumentList,
        isWithinPeriod
    }
}


