<script setup>
import {useDocument} from "@/utils/useDocument";
import {useRouter} from "vue-router";
import ErrorModal from "@/components/ErrorModel.vue";
import {onMounted, inject} from "vue";
import {useErrorModal} from "@/utils/useErrorModal";

const faceRecognition = inject("faceRecognition")

const {
  errorInfo,
  documents,
  isWithinPeriod,
  getDocumentList
} = useDocument()
const { close } = useErrorModal(errorInfo)
const router = useRouter();

onMounted(async () => {
  documents.value = await getDocumentList()
})

const handleClickDocument = (docID, docHash, disabled) => {
  if(disabled) {
    return;
  }
  router.push({
    path: "/viewer",
    query: {
      docID,
      docHash
    }
  })
}

const displayDocument = (document) => {
  return `${ document.docName ? document.docName.replace(".pdf", "") : "" } 閲覧期限：${document.expDateStart}～${document.expDateEnd}まで`
}
</script>

<template>
  <div>
    <h1>文書一覧</h1>
    <ul class="file-list">
      <li v-for="document in documents" :key="document.docID" :class="{ 'disabled': !isWithinPeriod(document) }">
        <a href="#" @click.prevent="handleClickDocument(document.docID, document.docHash, !isWithinPeriod(document))">{{ displayDocument(document) }}</a>
      </li>
    </ul>
    <ErrorModal @close="close" :show-modal="errorInfo.showModal && faceRecognition.enabled" :error-code="errorInfo.errorCode" />
  </div>
</template>

<style scoped>
.file-list {
  list-style: none;
  padding: 0;
}

.file-list li {
  background-color: #fff;
  margin: 10px 0;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
}

.file-list li:hover {
  transform: translateY(-5px);
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.2);
}

.file-list li a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  display: block;
  padding: 15px;
}

.file-list li a:hover {
  color: #e44d26;
}

.file-list li.disabled {
  background-color: #f2f2f2;
  cursor: not-allowed;
}

.file-list li.disabled a {
  color: #999;
  font-weight: normal;
}


@media screen and (min-width: 817px) {
  .container {
    width: 80%;
  }
}

@media screen and (max-width: 816px) {
  .file-list {
    padding: 0 10px;
  }
}

@media screen and (min-width: 817px) {
  .file-list {
    padding: 0 100px;
  }
}

</style>
