import * as VueRouter from 'vue-router'
import PDFViewer from '../components/PDFViewer.vue'
import DocumentList from '../components/DocumentList.vue'

const router = VueRouter.createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: VueRouter.createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'documents',
            component: DocumentList
        },
        {
            path: '/viewer',
            name: 'viewer',
            component: PDFViewer,
            beforeEnter(to, from) {
                if (from === VueRouter.START_LOCATION) {
                    location.href = "/"
                }
            }
        }
    ]
})

export default router
